import React, { useState } from "react";
import axios from "axios";
import {
  MailIcon,
  PhoneIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";

export default function SectionOne() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitted },
  } = useForm();
  const onSubmit = (data) => {
    const msg = encodeURIComponent(
      "<b>----------Ф.И.О----------\n\n</b>" +
        `${data.fullname}\n\n` +
        "<b>----------Email----------\n\n</b>" +
        `${data.email}\n\n` +
        "<b>----------Сообщение----------\n\n</b>" +
        `${data.message}\n\n`
    );
    const url = `https://api.telegram.org/bot5052640201:AAEUK6n6ZcGqrAL0nwFvJYLjDjuZ4gfF-8k/sendMessage?chat_id=1965442320&parse_mode=HTML&text=${msg}`;
    axios
      .get(url)
      .then(function (response) {
        // actions.setStatus({ msg: "Ваша заявка принята" });
        // setTimeout(actions.resetForm, 3000);
        console.log(response);
        setIsSuccessfullySubmitted(response.data.ok);
        reset();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="relative bg-white mb-10">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-light tracking-tight text-gray-900 sm:text-3xl uppercase">
              Свяжитесь с нами
            </h2>
            <p className="mt-3 leading-6 text-gray-500 uppercase font-bold">
              Республика Узбекистан, город Наманган, улица Косонсой дом 40А
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <Link
                to="/contact"
                className="inline-flex justify-center items-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mb-2"
              >
                Посмотреть на карте
                <LocationMarkerIcon
                  className="ml-2 -mr-0.5 h-4 w-4"
                  aria-hidden="true"
                />
              </Link>
              <div className="mt-6">
                <div className="sr-only">Phone number</div>
                <div className="flex items-center">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-yellow-500"
                    aria-hidden="true"
                  />
                  <div className="flex flex-wrap">
                    <a
                      href="tel:+998502201212"
                      class="ml-3 hover:text-yellow-500"
                    >
                      +998 50 220 12 12
                    </a>
                    <a
                      href="tel:+998882510020"
                      class="ml-3 hover:text-yellow-500"
                    >
                      +998 88 251 00 20
                    </a>
                    <a
                      href="tel:+998972510020"
                      class="ml-3 hover:text-yellow-500"
                    >
                      +998 97 251 00 20
                    </a>
                    <a
                      href="tel:+998911840606"
                      class="ml-3 hover:text-yellow-500"
                    >
                      +998 91 184 06 06
                    </a>
                    <a
                      href="tel:+998882590101"
                      class="ml-3 hover:text-yellow-500"
                    >
                      +998 88 259 01 01
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-yellow-500"
                    aria-hidden="true"
                  />
                  <a
                    href="mailto:orzu.imkon@mail.ru"
                    className="ml-3 hover:text-yellow-500"
                  >
                    orzu.imkon@mail.ru
                  </a>
                </dd>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-yellow-500"
                    aria-hidden="true"
                  />
                  <a
                    href="mailto:trikotazoptomuzbekistan@gmail.com"
                    className="ml-3 hover:text-yellow-500"
                  >
                    trikotazoptomuzbekistan@gmail.com
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="grid grid-cols-1 gap-y-6"
            >
              <div>
                <label htmlFor="fullname" className="sr-only">
                  Ф.И.О
                </label>
                <input
                  {...register("fullname", { required: true })}
                  type="text"
                  name="fullname"
                  id="fullname"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-yellow-500 focus:border-yellow-500 border border-gray-300 rounded-md"
                  placeholder="Ф.И.О"
                  disabled={isSuccessfullySubmitted}
                />
                {errors.fullname && (
                  <p className="text-yellow-600 text-xs">Обязательное поле</p>
                )}
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>

                <input
                  {...register("email", { required: true })}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-yellow-500 focus:border-yellow-500 border border-gray-300 rounded-md"
                  placeholder="Email"
                  disabled={isSuccessfullySubmitted}
                />
                {errors.email && (
                  <p className="text-yellow-600 text-xs">Обязательное поле</p>
                )}
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Сообщение
                </label>
                <textarea
                  {...register("message", { required: true })}
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-yellow-500 focus:border-yellow-500 border border-gray-300 rounded-md"
                  placeholder="Сообщение"
                  defaultValue={""}
                  disabled={isSuccessfullySubmitted}
                />
                {errors.message && (
                  <p className="text-yellow-600 text-xs">Обязательное поле</p>
                )}
              </div>
              {isSuccessfullySubmitted && (
                <div className="text-green-500">
                  Сообщение успешно отправлено!
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Отправить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
