import React from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

const Ymaps = ({ height }) => (
  <div className="box">
    <YMaps>
      <div>
        <Map
          width="100%"
          height={height}
          defaultState={{
            center: [41.017437, 71.641781],
            zoom: 10,
          }}
        >
          <Placemark geometry={[41.017437, 71.641781]} />
        </Map>
      </div>
    </YMaps>
  </div>
);

export default Ymaps;
