import * as React from "react";
import Layout from "../components/layout";
import SectionOne from "../components/home-sections/section-one";
import Ymaps from "../components/maps";

export default function ContactPage() {
  return (
    <Layout
      title="Контакты"
      description="Трикотажная фабрика IMKON. Элегантность, удобство, качество"
    >
      <Ymaps height="50vh" />
      <SectionOne />
    </Layout>
  );
}
